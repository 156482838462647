import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { checkToken } from "redux/auth/action";
import { getConnectionAndDirection } from "redux/app/action";
import "./Connection.scss";

const Connection = () => {
  const dispatch = useDispatch();
  const { workplaceNumber } = useSelector((state) => state.authReducer);
  const { workDirection, masterIdConnection } = useSelector(
    (state) => state.appReducer
  );

  useEffect(() => {
    dispatch(checkToken());
    document.title =
      masterIdConnection === 0
        ? "Ожидание подключения мастера | TOTO"
        : "Ожидание клиента | TOTO";

    // dispatch(getConnectionAndDirection());

    let intervalId = setInterval(function () {
      dispatch(getConnectionAndDirection());
    }, 8000);
    console.log("ced");
    return () => clearInterval(intervalId);
  }, []);

  const logOut = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  if (masterIdConnection === 0) {
    return (
      <div id='connection'>
        <h1>Номер монитора:</h1>
        <h2>{workplaceNumber}</h2>
        <button onClick={logOut} className='logout'>
          Выйти из сессии монитора
        </button>
      </div>
    );
  } else {
    if (workDirection === 1) {
      return <Redirect to='/video' />;
    } else {
      return (
        <div id='connection'>
          <div className='img_wrapper'>
            <img src='https://api.master-top.by/img/LogoBig.png' alt='' />
          </div>

          <h3>{workplaceNumber}</h3>

          <button onClick={logOut} className='logout'>
            Выйти из сессии монитора
          </button>
        </div>
      );
    }
  }
};

export default Connection;
